<template>
  <div class="container-fluid bg-primary text-white">
    <div ref="box" class="sliding-holder position-relative" style="width: 400px; height: 50px">
      <span ref="text1" class="h5 position-absolute" style="white-space: nowrap; left: 0;">
        Academy of Visual Arts, Hong Kong Baptist University&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span ref="text1-clone" class="h5 position-absolute" style="white-space: nowrap;">
        Academy of Visual Arts, Hong Kong Baptist University&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </div>
  </div>
  <button
    class="btn btn-primary text-uppercase mt-3"
    @click="overflowShow"
  >
    Overflow visible
  </button>
  <button
    class="btn btn-primary text-uppercase mt-3 ms-3"
    @click="overflowHide"
  >
    Overflow hidden
  </button>
</template>

<script>
import gsap from 'gsap';

export default {
  data() {
    return {
      revealEnterIndex: 0,
      textWidth: [],
      startPos: [
        {
          start: 0,
          target1: 0,
          target2: 0,
          cloneStart: 0,
        },
      ],
      tempPos: '',
    };
  },
  methods: {
    initPosition() {
      this.textWidth[0] = this.$refs.text1.getBoundingClientRect().width;
      this.startPos[0].target1 = -this.textWidth[0];
      [this.startPos[0].target2] = this.textWidth;
      this.startPos[0].cloneStart = this.textWidth;
      this.$refs['text1-clone'].style.left = this.formatPos(this.textWidth);
    },
    // start the render loop animation (rAF)
    loopRender() {
      if (!this.requestId) {
        this.requestId = requestAnimationFrame(() => {
          this.render(this.revealEnterIndex);
        });
      }
    },
    // stop the render loop animation (rAF)
    stopRendering() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
      }
    },
    formatPos(pos) {
      return `${pos}px`;
    },
    render() {
      this.requestId = undefined;
      if (this.startPos[0].start <= this.startPos[0].target1) {
        this.startPos[0].start = this.startPos[0].target2;
      } else {
        this.startPos[0].start -= 1;
      }
      if (this.startPos[0].cloneStart <= this.startPos[0].target1) {
        this.startPos[0].cloneStart = this.startPos[0].target2;
      } else {
        this.startPos[0].cloneStart -= 1;
      }
      gsap.set(this.$refs.text1, { left: this.formatPos(this.startPos[0].start) });
      gsap.set(this.$refs['text1-clone'], { left: this.formatPos(this.startPos[0].cloneStart) });
      // if (this.startPos[0].start <= this.startPos[0].target1) {
      //   this.startPos[0].start = this.startPos[0].target2;
      // } else {
      //   this.startPos[0].start -= 1;
      // }
      // if (this.startPos[0].cloneStart <= this.startPos[0].target1) {
      //   this.startPos[0].cloneStart = this.startPos[0].target2;
      // } else {
      //   this.startPos[0].cloneStart -= 1;
      // }
      // gsap.set(this.$refs.text0, { left: this.formatPos(this.startPos[0].start) });
      // gsap.set(this.$refs['text0-clone'], { left: this.formatPos(this.startPos[0].cloneStart) });

      // if (this.startPos[1].start <= this.startPos[1].target1) {
      //   this.startPos[1].start = this.startPos[1].target2;
      // } else {
      //   this.startPos[1].start -= 1;
      // }
      // if (this.startPos[1].cloneStart <= this.startPos[1].target1) {
      //   this.startPos[1].cloneStart = this.startPos[1].target2;
      // } else {
      //   this.startPos[1].cloneStart -= 1;
      // }
      // gsap.set(this.$refs.text1, { left: this.formatPos(this.startPos[1].start) });
      // gsap.set(this.$refs['text1-clone'], { left: this.formatPos(this.startPos[1].cloneStart) });

      // if (this.startPos[2].start <= this.startPos[2].target1) {
      //   this.startPos[2].start = this.startPos[2].target2;
      // } else {
      //   this.startPos[2].start -= 1;
      // }
      // if (this.startPos[2].cloneStart <= this.startPos[2].target1) {
      //   this.startPos[2].cloneStart = this.startPos[2].target2;
      // } else {
      //   this.startPos[2].cloneStart -= 1;
      // }
      // gsap.set(this.$refs.text2, { left: this.formatPos(this.startPos[2].start) });
      // gsap.set(this.$refs['text2-clone'], { left: this.formatPos(this.startPos[2].cloneStart) });

      // if (this.startPos[3].start <= this.startPos[3].target1) {
      //   this.startPos[3].start = this.startPos[3].target2;
      // } else {
      //   this.startPos[3].start -= 1;
      // }
      // if (this.startPos[3].cloneStart <= this.startPos[3].target1) {
      //   this.startPos[3].cloneStart = this.startPos[3].target2;
      // } else {
      //   this.startPos[3].cloneStart -= 1;
      // }
      // gsap.set(this.$refs.text3, { left: this.formatPos(this.startPos[3].start) });
      // gsap.set(this.$refs['text3-clone'], { left: this.formatPos(this.startPos[3].cloneStart) });

      // if (this.startPos[4].start <= this.startPos[4].target1) {
      //   this.startPos[4].start = this.startPos[4].target2;
      // } else {
      //   this.startPos[4].start -= 1;
      // }
      // if (this.startPos[4].cloneStart <= this.startPos[4].target1) {
      //   this.startPos[4].cloneStart = this.startPos[4].target2;
      // } else {
      //   this.startPos[4].cloneStart -= 1;
      // }
      // gsap.set(this.$refs.text4, { left: this.formatPos(this.startPos[4].start) });
      // gsap.set(this.$refs['text4-clone'], { left: this.formatPos(this.startPos[4].cloneStart) });

      // if (this.startPos[5].start <= this.startPos[5].target1) {
      //   this.startPos[5].start = this.startPos[5].target2;
      // } else {
      //   this.startPos[5].start -= 1;
      // }
      // if (this.startPos[5].cloneStart <= this.startPos[5].target1) {
      //   this.startPos[5].cloneStart = this.startPos[5].target2;
      // } else {
      //   this.startPos[5].cloneStart -= 1;
      // }
      // gsap.set(this.$refs.text5, { left: this.formatPos(this.startPos[5].start) });
      // gsap.set(this.$refs['text5-clone'], { left: this.formatPos(this.startPos[5].cloneStart) });
      this.loopRender();
    },
    overflowShow() {
      this.$refs.box.style.overflow = 'visible';
    },
    overflowHide() {
      this.$refs.box.style.overflow = 'hidden';
    },
  },
  mounted() {
    this.initPosition();
    this.loopRender();
  },
  unmounted() {
    this.stopRendering();
  },
};
</script>
